import React, { useContext, useEffect, useState } from 'react'
import {
    currencyFormat,
    currencyFormatInt,
    currencyFormatTwo,
    handleRedirectInternal,
    numberFormatPositive,
} from '../../../common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import authContext from '../../../../product/context/auth/authContext'
import TertiaryButton from '../../atoms/TertiaryButton'
import CustomDialog from '../../organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { mapData } from '../../../../product/common/components'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import KlaviyoContext from '../../../../custom/context/klaviyo/klaviyoContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { v4 as uuidv4 } from 'uuid'

const Buynow = (props) => {
    // let randomVariable = Math.random()
    const { isAuthenticated, user } = useContext(authContext)
    const { addToCart, addToCartTemp, responseStatus } = useContext(BuynowContext)
    const [product, setProduct] = useState()
    const { northCapitalUser } = useContext(NorthCapitalContext)
    const { setAlert } = useContext(AlertContext)

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const [canadianUser, setCanadianUser] = useState(false)

    const { updateProfile } = useContext(KlaviyoContext)

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (user && user.country == 'CA') {
            setCanadianUser(true)
        }
    }, [user])

    /*  useEffect(() => {
        if (product) {
            formik.setFieldValue('auctiontype', product.auctiontype ? product.auctiontype : '')
        }
    }, [product]) */

    const validationArray = Yup.object({
        product_id: Yup.number().required('Product id required'),
        qty: Yup.number()
            .min(1, `${'Min quantity'} `)
            .integer("Can't accept decimal values")
            .max(
                product
                    ? !product.isPublicVisible && product.privateQty
                        ? product.privateQty - product.privateSold
                        : numberFormatPositive(
                              product.qty -
                                  product.sold -
                                  (product.booked > 0 ? product.booked : 0),
                          )
                    : 0,
                `${'Max quantity'} ${
                    product
                        ? !product.isPublicVisible && product.privateQty
                            ? product.privateQty
                                ? product.privateQty - product.privateSold
                                : 0
                            : numberFormatPositive(
                                  product.qty -
                                      product.sold -
                                      (product.booked > 0 ? product.booked : 0),
                              )
                        : 0
                }`,
            )
            /* .when('auctiontype', {
                is: 'buynow',
                then: Yup.number().integer("Can't include a decimal point"),
                otherwise: Yup.number(),
            }) */
            .integer('Whole numbers only')
            .required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: {
            product_id: props.data.id,
            qty: '' /* , auctiontype: '' */,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (isAuthenticated) {
                if (!user?.mail_verified) {
                    setAlert('Please verify your email', 'error')
                    handleRedirectInternal(history, 'account/profile')
                } else if (
                    northCapitalUser &&
                    ((user.domesticYN !== 'domestic_account' && user.user_type == 0) ||
                        user.user_type == 1)
                ) {
                    if (northCapitalUser.isDocumentUploaded !== 1) {
                        setAlert(
                            'Your account has not been verified. Please visit your Settings to upload a passport or photo ID before completing this purchase.',
                            'error',
                        )
                        // handleRedirectInternal(history, 'registration/DocumentUpdate/1')
                        return false
                    }
                }
            }

            if (toggleDialog) {
                let cart = []
                if (product.auctiontype === 'buynow' && product.isPublicVisible !== true) {
                    values.isNoTimer = true
                }
                cart.push(values)
                if (!isAuthenticated) {
                    if (localStorage.temp_user_id) {
                        addToCartTemp({
                            temp_user_id: localStorage.temp_user_id,
                            cart: cart,
                        })
                    } else {
                        const id = uuidv4()
                        localStorage.setItem('temp_user_id', id)
                        addToCartTemp({
                            temp_user_id: id,
                            cart: cart,
                        })
                        console.log(cart, id, 'values buynow')
                    }
                } else {
                    addToCart({ cart })
                }

                changeDialogStatus()
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    const cartProduct = responseStatus.data[0]
                    var _learnq = window._learnq || []
                    var itemArr = {
                        $value: cartProduct.qty * product.bprice,
                        AddedItemProductName: product.title,
                        AddedItemProductID: product.id,
                        AddedItemCategories: 'SongShares',
                        AddedItemURL: window.location.href,
                        AddedItemPrice: product.bprice,
                        AddedItemQuantity: cartProduct.qty,
                        ItemNames: [product.title],
                        CheckoutURL: `${global.site_url}/checkout/buynow/${cartProduct.cart_group}`,
                        Items: [
                            {
                                ProductID: product.id,
                                ProductName: product.title,
                                Quantity: cartProduct.qty,
                                ItemPrice: product.bprice,
                                RowTotal: cartProduct.qty * product.bprice,
                                ProductURL: window.location.href,
                                ProductCategories: product.auctiontype,
                            },
                        ],
                    }
                    _learnq.push(['track', 'Added to Cart', itemArr])
                    console.log(_learnq, 'trigger', ['track', 'Added to Cart', itemArr])
                    // updateProfile({
                    //     email: user.email,
                    //     'Last Added to Cart': new Date().toLocaleString('en-US', {
                    //         year: 'numeric',
                    //         month: '2-digit',
                    //         day: '2-digit',
                    //     }),
                    // })
                    setToggleDialog(false)
                    setTimeout(() => {
                        handleRedirectInternal(history, 'buynowcart')
                    }, 2000)
                }
            } else if (responseStatus.from === 'addToCartTemp') {
                if (responseStatus.status === 'success') {
                    if (!isAuthenticated) {
                        setToggleDialog(false)
                        setTimeout(() => {
                            handleRedirectInternal(history, 'buynowcart')
                        }, 2000)
                    }
                }
            }
        }
    }, [responseStatus])

    const buynowQuantity = [
        {
            name: 'qty',
            type: 'number',
            placeholder: `Enter unit qty`, //`${'Enter No. of Shares'} ${product ? product.qty - product.sold : 0}`,
            class: '',
            autoFocus: false,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (formik.values.qty > 0) {
            if (!formik.touched || !Object.keys(formik.touched).length) {
                formik.setTouched({ qty: true }, true)
            }
        }
    }, [formik.values.qty])

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt buyNnwContainer beforeLogin d-flex justify-content-start align-items-start">
                                {props.editQuantity && Object.values(mapData(buynowQuantity))}

                                {isAuthenticated ? (
                                    <PrimaryButton
                                        id="component_buynow_button"
                                        label={'Buy now'}
                                        type="submit"
                                        btnSize="small"
                                        disabled={
                                            (product &&
                                                !product.isPublicVisible &&
                                                !product.isPrivateVisible) ||
                                            canadianUser ||
                                            false
                                        }
                                    />
                                ) : product &&
                                  !product.isPublicVisible &&
                                  !product.isPrivateVisible ? (
                                    <PrimaryButton
                                        id="component_logintobuynow_button"
                                        label="Login to buy"
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                ) : (
                                    <PrimaryButton
                                        id="component_logintobuynow_button"
                                        label="Buy now"
                                        btnSize="small"
                                        type="submit"
                                        // onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>

                            {formik.values.qty && formik.isValid ? (
                                <div className="ryltsDtlsWrpr">
                                    <div className="rylInrWrpr d-flex align-items-center">
                                        <span>
                                            {currencyFormatTwo(formik.values.qty * product.bprice)}
                                        </span>
                                        <p>Total Price</p>
                                    </div>
                                    {currencyFormat(
                                        formik.values.qty *
                                            parseFloat(
                                                product.annual_royalties / product.qty,
                                            ).toFixed(2),
                                    ) !== '$0.00' && product.annual_royalties != 0 ? (
                                        <div className="rylInrWrpr d-flex align-items-center">
                                            <span>
                                                {currencyFormat(
                                                    formik.values.qty *
                                                        parseFloat(
                                                            product.annual_royalties / product.qty,
                                                        ).toFixed(2),
                                                )}
                                            </span>
                                            <p>Total Royalties Paid (Last 12 Months)</p>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog title="Confirm buy now" open={toggleDialog} function={changeDialogStatus}>
                <h5>You are about to buy this product</h5>
                <h5>Please confirm if you want to continue with this action</h5>
                <div className="actionWrapper">
                    <Button id="component_buynow_cancel" onClick={() => setToggleDialog(false)}>
                        Cancel
                    </Button>
                    <PrimaryButton
                        id="component_buynow_submit"
                        onClick={formik.handleSubmit}
                        type="submit"
                        label="Submit"
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default Buynow
